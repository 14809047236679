<template>
  <div>
    <VueSlickCarousel v-bind="settings">
      <slide-one></slide-one>
      <slide-two></slide-two>
      <slide-three></slide-three>
    </VueSlickCarousel>
  </div> 
</template>

<script>
import SlideOne from "./slideOne.vue";
import SlideTwo from "./slideTwo.vue";
import SlideThree from "./slideThree.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  components: { VueSlickCarousel, SlideOne, SlideTwo, SlideThree },
  data() {
    return {
      settings: {
        dots: true,
        arrows: false,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    };
  },
};
</script>
<template>
  <div class="max-h-screen relative">
    <transition name="fade">
      <div
        v-if="showForgotPassword"
        class="bg-black fixed bg-opacity-80 inset-0 z-50 h-screen w-full"
      >
        <div
          class="
            fixed
            center-h-v
            bg-white
            rounded-md
            sm:w-1/3
            w-full
            shadow-2xl
          "
        >
          <div v-if="loading" class="absolute inset-0 bg-black bg-opacity-50">
            <loader class="absolute center-h-v" />
          </div>
          <div
            class="flex justify-between items-center text-sm border-b-1.5 p-3"
          >
            <p>Forgot Password</p>
            <img
              v-if="!loading"
              class="
                h-5
                object-contain
                bg-buttonColor
                rounded-md
                cursor-pointer
                hover:shadow-lg
              "
              src="@/assets/icons/cross-white.png"
              @click="showForgotPassword = false"
            />
          </div>
          <form class="m-3" @submit.prevent="resetPassword">
            <div class="flex flex-col items-center justify-center">
              <p class="text-center font-medium text-sm text-primaryDark p-5">
                Provide your account's email for which you want to reset your
                password!
              </p>
              <input
                type="email"
                required
                v-model="resetPasswordEmail"
                placeholder="Email address"
                class="
                  border-0.5
                  text-xs
                  border-inputBorder
                  p-3
                  rounded
                  w-3/4
                  mb-6
                "
              />
            </div>
            <div class="flex justify-center">
              <button
                class="
                  bg-buttonColor
                  text-white
                  p-2
                  rounded
                  w-3/4
                  mb-6
                  hover:bg-primaryHover
                  focus:outline-none
                "
              >
                Reset password
              </button>
            </div>
          </form>
        </div>
      </div>
      >
    </transition>
    <transition name="fade">
      <div
        v-if="loading"
        class="inset-0 absolute toppest bg-black bg-opacity-90 h-screen w-full"
      >
        <loader class="center-h-v" />
      </div>
    </transition>
    <div class="h-screen-10 w-full bg-navbarColor">
      <auth-nav-bar />
    </div>
    <div class="h-screen-90 grid grid-cols-1 lg:grid-cols-2 relative">
      <img
        src="@/assets/images/auth-phone.png"
        class="h-3/4 absolute center-h-v z-40 mt-12 hidden lg:block"
      />
      <div class="flex justify-center flex-col sm:ml-20">
        <div class="text-textColorGray">
          <!-- <p class="text-xs sm:px-0 px-10">
            Welcome back, please login to your account
          </p> -->
          <div
            v-if="organization"
            class="
              flex
              items-center
              gap-5
              divide-x-0.5
              sm:px-0
              px-10
              divide-textColorGray
            "
          >
            <img class="h-10 sm:h-14 object-contain" :src="organization.logo" />
            <p class="px-3 sm:text-lg text-sm font-semibold">
              {{ organization.name }}
            </p>
          </div>

          <h1 class="text-sm font-medium py-5 sm:py-10 sm:px-0 px-10">
            Welcome to Lipalink, please login to your account
          </h1>
          <form
            class="w-full sm:px-0 px-10 flex flex-col"
            @submit.prevent="login"
          >
            <transition name="fade">
              <div
                v-if="resetPasswordEmailSent"
                class="
                  text-xs
                  border-1.5 border-green-400
                  mb-6
                  flex
                  items-center
                  p-2
                  gap-4
                  sm:w-4/6
                "
              >
                <img class="h-5" src="@/assets/icons/check.png" />
                <p class="font-bold">
                  We have sent you an email. Follow the instructions outlined on
                  the email on how to reset your password.
                </p>
              </div>
            </transition>
            <transition name="fade">
              <div
                v-if="error"
                class="
                  text-xs
                  border-1.5 border-red-400
                  mb-6
                  flex
                  items-center
                  p-2
                  gap-4
                  sm:w-4/6
                "
              >
                <img class="h-5" src="@/assets/icons/cross.png" />
                <p class="font-bold">{{ error }}</p>
              </div>
            </transition>
            <input
              v-model="info.username"
              placeholder="Username"
              type="text"
              required
              class="
                border-0.5
                text-xs
                font-semibold
                border-inputBorder
                p-3
                rounded
                sm:w-4/6
                mb-6
              "
            />
            <div
              class="
                flex
                sm:w-4/6
                items-center
                gap-5
                border-0.5
                p-3
                rounded
                border-inputBorder
                mb-2
              "
            >
              <input
                v-model="info.password"
                required
                :type="type"
                placeholder="Password"
                class="text-xs font-semibold w-full focus:outline-none"
              />
              <img
                @click="showPassword"
                class="h-3 cursor-pointer"
                :src="type === 'password' ? hidePasswordIcon : showPasswordIcon"
              />
            </div>
            <div class="text-xs py-6 pl-1 flex">
              <!-- <input type="checkbox" /> Remember me -->
              <p
                @click="showForgotPassword = true"
                class="underline cursor-pointer hover:text-buttonColor"
              >
                Forgot Password?
              </p>
            </div>
            <button
              type="submit"
              class="
                bg-buttonColor
                hover:bg-primary
                text-white
                sm:w-4/6
                p-2
                sm:p-3
                mt-0
                sm:mt-6
                rounded
                font-semibold
                text-sm
              "
            >
              LOGIN
            </button>

            <!-- <p
              @click="goToEnroll"
              class="
                text-sm
                mt-5
                cursor-pointer
                text-buttonColor
                hover:text-black
              "
            >
              Enroll your organization?
            </p> -->
          </form>
        </div>
      </div>
      <div
        class="
          bg-gradient
          h-full
          relative
          overflow-hidden
          flex flex-col
          justify-center
        "
      >
        <div class="sm:pl-40 pl-10 sm:pr-20 pr-10 pb-20 mt-2">
          <text-slider></text-slider>
        </div>
        <!-- <div class="flex justify-center">
          <button
            @click="goToEnroll"
            class="
              bg-white
              py-2
              px-6
              sm:block
              hidden
              rounded-full
              font-semibold
              shadow-lg
            "
          >
            Enroll Now!
          </button>
        </div> -->

        <div class="absolute bottom-0 w-full mb-8 text-white">
          <p class="text-center text-xs opacity-70">
            &copy; Copyright {{ new Date().getFullYear() }}.
            <a class="text-black" href="sepia.co.ke">Sepia consulting</a> All
            Rights Reserved
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TextSlider from "../../components/auth/textSlider.vue";
import AuthNavBar from "../../components/auth/authNavbar.vue";
var qs = require("qs");
import Loader from "../../components/loader/loader.vue";
export default {
  name: "Login",
  components: { AuthNavBar, Loader, TextSlider },
  data() {
    return {
      info: {
        username: this.$route.query.username,
        password: this.$route.query.password,
        grant_type: "password",
      },
      organization: null,
      resetPasswordEmail: null,
      resetPasswordEmailSent: false,
      loading: false,
      showForgotPassword: false,
      error: null,
      type: "password",
      showPasswordIcon: require("@/assets/icons/eye.png"),
      hidePasswordIcon: require("@/assets/icons/eye-crossed.png"),
    };
  },
  created() {
    if (this.info.username && this.info.password) {
      this.login();
    }
    this.getOrganization();
  },
  methods: {
    getOrganization() {
      this.loading = true;
      axios.get(`/organization/${this.$route.params.organization}`).then(
        (res) => {
          this.organization = res.data;
          this.loading = false;
          if (this.$route.params.organization === "admin") {
            document.title = `Lipa Link | Admin`;
          } else {
            document.title = `Lipa Link | ${this.organization.name}`;
          }
        },
        () => {
          this.$router.replace({ name: "NotFound" });
        }
      );
    },
    showPassword() {
      this.type = this.type === "password" ? "text" : "password";
    },
    goToEnroll() {
      this.$router.push({
        name: "enroll",
      });
    },
    resetPassword() {
      this.loading = true;
      let data = {
        Email: this.resetPasswordEmail,
      };
      axios
        .post(
          `${this.$route.params.organization}/account/forgot/password`,
          qs.stringify(data)
        )
        .then(
          () => {
            this.loading = false;
            this.showForgotPassword = false;
            this.resetPasswordEmailSent = true;
            setTimeout(() => {
              this.resetPasswordEmailSent = false;
            }, 10000);
          },
          () => {
            this.loading = false;
          }
        );
    },
    login() {
      this.loading = true;
      this.error = null;
      const options = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      axios.post("oauth/generate", qs.stringify(this.info), options).then(
        (response) => {
          this.loading = false;
          this.$store.commit("login", response.data);
          this.$store.commit(
            "setOrganization",
            this.$route.params.organization
          );
          if (response.data.passwordChanged == "True") {
            this.$router.replace({
              name: "dashboard",
              params: { organization: this.$route.params.organization },
            });
          } else {
            this.$router.replace({ name: "changePassword" });
          }
        },
        (error) => {
          if (error.response) {
            this.error = error.response.data.error_description;
          } else {
            this.error = error.message;
          }
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style scoped>
.toppest {
  z-index: 99999;
}
.center-h-v {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.h-screen-10 {
  height: 10vh;
}
.h-screen-90 {
  height: 90vh;
}
.bg-gradient {
  background: rgb(252, 186, 30);
  background: -moz-linear-gradient(
    135deg,
    rgba(252, 186, 30, 1) 0%,
    rgba(226, 122, 1, 1) 100%
  );
  background: -webkit-linear-gradient(
    135deg,
    rgba(252, 186, 30, 1) 0%,
    rgba(226, 122, 1, 1) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(252, 186, 30, 1) 0%,
    rgba(226, 122, 1, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fcba1e",endColorstr="#e27a01",GradientType=1);
}
</style>

